<template>

  <modele-form
    v-if="modeleFormData"
    :title="title"
    :submit-button-text="submitButtonText"
    :modele-form-data="modeleFormData"
    @submitModeleForm="onSubmit($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ModeleForm from './ModeleForm.vue'

export default {
  components: {
    ModeleForm,
  },
  data() {
    return {
      title: 'Mise à jour d\'un modèle',
      submitButtonText: 'Modifier',
      modeleFormData: undefined,
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { modeleId } = router.currentRoute.params
      store.dispatch('modeles/fetchModeleById', modeleId)
        .then(response => {
          this.modeleFormData = response.data
        })
    })
  },
  setup() {
    const toast = useToast()
    const { modeleId } = router.currentRoute.params

    const onSubmit = formModeleValue => store.dispatch('modeles/updateModele', { formModeleValue, modeleId })
      .then(() => {
        router.push({ name: 'modeles-list', params: { id: router.currentRoute.params.marqueId } })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erreur lors de la mise à jour d\'un modèle',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      onSubmit,
    }
  },
}
</script>
